jQuery(document).ready(function($)
{

     $(".deleteBtn").click( function(){

         var deleteUrl = $(this).data('url');

         $('#deleteConfirmed').attr("href",deleteUrl);

         $('#confirmation_modal').modal("show");
         
     });


});
